import Vue from 'vue';
import App from './App.vue';
import router from './routers';
import './utils/element';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

import VueLazyLoad from 'vue-lazyload';
Vue.use(VueLazyLoad);

// 隐藏右侧浏览器滚动条
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

// 判断是否为移动端
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// 如果是移动端，直接跳转到指定的 URL
if (isMobile()) {
  window.location.href = 'https://app.pydance.cn'; // 替换为移动端 URL
} else {
  // 仅在 PC 端初始化 Vue
  new Vue({
    el: '#app',
    router,
    render: h => h(App),
  }).$mount('#app');

  // 滚动到页面顶部
  router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });
}